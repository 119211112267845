import React from 'react';

const UpRightArrowIcon = ({ size = 20, color = '#D42525', ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.666 11.7676C16.666 12.2278 16.2929 12.6009 15.8327 12.6009C15.3724 12.6009 14.9993 12.2278 14.9993 11.7676V7.10924L5.92852 16.1801C5.60405 16.5045 5.07798 16.5045 4.75352 16.1801C4.42905 15.8556 4.42905 15.3295 4.75352 15.0051L13.8243 5.93424H9.16602C8.70578 5.93424 8.33268 5.56115 8.33268 5.10091C8.33268 4.64067 8.70578 4.26758 9.16602 4.26758H15.666C16.2183 4.26758 16.666 4.71529 16.666 5.26758V11.7676Z"
        fill={color}
      />
    </svg>
  );
};

export default UpRightArrowIcon;
