import React from 'react';

const CheckIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H24C30.6274 0 36 5.37258 36 12V24C36 30.6274 30.6274 36 24 36H12C5.37258 36 0 30.6274 0 24V12Z"
        fill="#329546"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7511 21.0001L12.3761 17.6251C11.7548 17.0038 10.7475 17.0038 10.1262 17.6251C9.50483 18.2464 9.50482 19.2538 10.1261 19.8751L14.6905 24.4394C15.2762 25.0251 16.226 25.0251 16.8118 24.4394L25.8761 15.375C26.4975 14.7537 26.4975 13.7464 25.8762 13.1251C25.2548 12.5037 24.2475 12.5037 23.6262 13.125L15.7511 21.0001Z"
        fill="#F3FBF5"
      />
    </svg>
  );
};

export default CheckIcon;
