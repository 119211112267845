import React from 'react';

const BulbIcon = ({ size = 24, color = '#D42525' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.83891 2.28089C4.54603 1.98799 4.07116 1.98796 3.77825 2.28084C3.48534 2.57372 3.48532 3.04859 3.7782 3.3415L4.96814 4.53156C5.26102 4.82447 5.73589 4.82449 6.0288 4.53161C6.32171 4.23874 6.32173 3.76386 6.02885 3.47095L4.83891 2.28089Z"
        fill={color}
      />
      <path
        d="M22.2188 4.34242C22.5117 4.04955 22.5117 3.57467 22.2188 3.28176C21.9259 2.98886 21.4511 2.98883 21.1581 3.28171L19.9681 4.47165C19.6752 4.76453 19.6752 5.2394 19.968 5.53231C20.2609 5.82522 20.7358 5.82524 21.0287 5.53236L22.2188 4.34242Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.7478 18.0111L8.74777 18.5013C8.74768 20.2965 10.2024 21.7514 11.9976 21.7515L12.9976 21.7515C14.7928 21.7516 16.2477 20.2969 16.2478 18.5017L16.2479 15.7347C16.2479 15.6768 16.2788 15.5892 16.3877 15.5122C18.1142 14.2925 19.2481 12.2818 19.2482 10.0018C19.2484 6.00628 15.7752 2.81995 11.6863 3.29956C8.73277 3.64544 6.29047 5.98201 5.83219 8.92377C5.41102 11.6318 6.60991 14.0991 8.6061 15.5098C8.71526 15.5872 8.74792 15.6766 8.74791 15.7383L8.7478 17.9917C8.74776 17.9949 8.74774 17.9981 8.74774 18.0014C8.74774 18.0046 8.74776 18.0078 8.7478 18.0111ZM17.7482 10.0018C17.7484 6.8934 15.0479 4.41546 11.8609 4.78937C9.57251 5.05729 7.66838 6.88242 7.31434 9.15447L17.7482 10.0018ZM17.7482 10.0018L7.31434 9.15447C6.98535 11.2703 7.91617 13.1855 9.47181 14.2849C9.53898 14.3324 9.60395 14.3849 9.6652 14.4413L15.3308 14.4416C15.3916 14.3858 15.4556 14.3342 15.5222 14.2871C16.8698 13.335 17.7481 11.7718 17.7482 10.0018ZM10.2479 15.9414L14.7479 15.9416L14.7478 17.2517L10.2478 17.2514L10.2479 15.9414ZM10.2655 18.7514L14.7478 18.7517C14.6265 19.5999 13.8796 20.2516 12.9977 20.2515L11.9977 20.2515C11.1158 20.2514 10.3867 19.5997 10.2655 18.7514Z"
        fill={color}
      />
      <path
        d="M5.0283 14.4708C5.32118 14.7638 5.32116 15.2386 5.02825 15.5315L3.83819 16.7214C3.54528 17.0143 3.07041 17.0143 2.77753 16.7214C2.48465 16.4285 2.48468 15.9536 2.77758 15.6607L3.96764 14.4708C4.26055 14.1779 4.73542 14.1779 5.0283 14.4708Z"
        fill={color}
      />
      <path
        d="M19.9676 14.4716C20.2605 14.1787 20.7354 14.1787 21.0282 14.4716L22.2182 15.6617C22.5111 15.9546 22.511 16.4295 22.2181 16.7224C21.9252 17.0152 21.4503 17.0152 21.1575 16.7223L19.9675 15.5323C19.6747 15.2393 19.6747 14.7645 19.9676 14.4716Z"
        fill={color}
      />
      <path
        d="M21.4982 9.252C21.084 9.25198 20.7482 9.58775 20.7481 10.002C20.7481 10.4162 21.0839 10.752 21.4981 10.752L23.1881 10.7521C23.6023 10.7521 23.9381 10.4163 23.9381 10.0021C23.9382 9.58791 23.6024 9.2521 23.1882 9.25208L21.4982 9.252Z"
        fill={color}
      />
      <path
        d="M1.0582 10.001C1.05822 9.58676 1.39402 9.25099 1.80823 9.25101L3.49823 9.2511C3.91245 9.25112 4.24822 9.58692 4.2482 10.0011C4.24818 10.4153 3.91237 10.7511 3.49816 10.7511L1.80816 10.751C1.39395 10.751 1.05818 10.4152 1.0582 10.001Z"
        fill={color}
      />
    </svg>
  );
};

export default BulbIcon;
