import React from 'react';

const EyeIcon = ({ size = 20, color = 'white', opacity = 0.6 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.15168 9.93714C3.90205 8.54944 4.96936 7.25796 6.18398 6.3193C7.40203 5.37798 8.72723 4.82153 10.0036 4.82153C11.2799 4.82153 12.6051 5.37798 13.8231 6.31938C15.0377 7.2581 16.105 8.54971 16.8553 9.93777C16.886 9.99482 16.886 10.0655 16.8555 10.1226C16.1051 11.5103 15.0378 12.8018 13.8232 13.7404C12.6051 14.6817 11.2799 15.2382 10.0036 15.2382C8.72723 15.2382 7.40204 14.6817 6.18401 13.7403C4.96947 12.8017 3.90223 11.5101 3.15187 10.1221C3.12106 10.0648 3.12081 9.99436 3.15168 9.93714ZM10.0036 3.57153C8.36742 3.57153 6.78012 4.27883 5.41963 5.33023C4.05571 6.38427 2.87816 7.81497 2.05186 9.3431C1.82109 9.77082 1.82134 10.2885 2.0521 10.7162C2.8784 12.2448 4.05567 13.6752 5.4196 14.7294C6.78011 15.7809 8.36741 16.4882 10.0036 16.4882C11.6397 16.4882 13.227 15.7809 14.5875 14.7295C15.9514 13.6755 17.129 12.2448 17.9553 10.7166C18.186 10.2889 18.1858 9.77121 17.955 9.34349C17.1287 7.81493 15.9515 6.38451 14.5875 5.33035C13.227 4.27884 11.6397 3.57153 10.0036 3.57153ZM8.65658 8.683C9.40043 7.93916 10.6065 7.93916 11.3503 8.683C12.0942 9.42685 12.0942 10.6329 11.3503 11.3767C10.6065 12.1206 9.40043 12.1206 8.65658 11.3767C7.91274 10.6329 7.91274 9.42685 8.65658 8.683ZM7.81477 7.84119C9.02354 6.63243 10.9833 6.63243 12.1921 7.84119C13.4009 9.04996 13.4009 11.0098 12.1921 12.2185C10.9833 13.4273 9.02354 13.4273 7.81477 12.2185C6.60601 11.0098 6.60601 9.04996 7.81477 7.84119Z"
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default EyeIcon;
