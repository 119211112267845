import React from 'react';

const TopLeftScribble = ({ size = 82, color = '#F7CC9C', ...rest }) => {
  return (
    <svg
      width={size}
      height={(size * 64) / 82}
      viewBox="0 0 82 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.28637 17.3416C6.66763 18.8332 10.7098 19.5532 14.4971 19.8916C17.7622 20.1834 19.1549 16.2929 16.635 14.1961C14.508 12.4263 12.4714 10.7317 11.4456 9.8786C8.75666 7.64235 4.11571 7.0869 1.62961 9.94949C-0.856499 12.8121 2.68029 16.3355 4.28637 17.3416Z"
        fill={color}
      />
      <path
        d="M6.61433 38.0641C9.03821 37.5628 12.1342 35.12 14.3534 33.1066C15.4218 32.1373 14.6544 30.4788 13.2154 30.5791C10.6481 30.7579 7.56181 30.9729 6.27329 31.063C3.88665 31.2299 1.4163 33.1327 1.66997 35.6963C1.92363 38.2599 5.3533 38.3249 6.61433 38.0641Z"
        fill={color}
      />
      <path
        d="M22.2616 60.0622C28.1905 49.8245 28.8684 42.2765 33.831 32.6629C34.4955 31.3757 36.3631 31.7417 36.5809 33.1739C36.7036 33.9806 36.8226 34.7486 36.935 35.4819C37.2115 37.286 41.9321 38.3776 42.4993 36.6428C44.1137 31.7053 45.2925 26.6857 46.8396 22.6793C47.359 21.3345 49.0891 21.6315 49.312 23.0558C49.992 27.401 50.6842 31.2143 51.2942 34.1501C51.5736 35.4949 53.2524 35.7163 53.8007 34.4569C56.2518 28.8267 57.7076 21.9388 59.5539 16.325C60.0238 14.8964 61.8299 15.118 62.0048 16.6117C62.4064 20.0407 62.5958 22.9745 62.9044 25.4323C63.0857 26.8751 64.6843 26.9313 65.0304 25.5189C66.6559 18.886 67.3129 11.4273 71.558 3.67509"
        stroke={color}
        strokeWidth="7.17093"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TopLeftScribble;
