import React from 'react';

const CrossIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H24C30.6274 0 36 5.37258 36 12V24C36 30.6274 30.6274 36 24 36H12C5.37258 36 0 30.6274 0 24V12Z"
        fill="#E24B19"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4399 23.9392C9.85408 24.525 9.85408 25.4748 10.4399 26.0605C11.0257 26.6463 11.9754 26.6463 12.5612 26.0605L18.0002 20.6216L23.4392 26.0606C24.0249 26.6463 24.9747 26.6463 25.5605 26.0606C26.1463 25.4748 26.1463 24.525 25.5605 23.9392L20.1215 18.5002L25.561 13.0607C26.1468 12.4749 26.1468 11.5252 25.561 10.9394C24.9752 10.3536 24.0255 10.3536 23.4397 10.9394L18.0002 16.3789L12.5607 10.9394C11.9749 10.3536 11.0251 10.3536 10.4393 10.9394C9.85355 11.5252 9.85355 12.475 10.4393 13.0607L15.8788 18.5002L10.4399 23.9392Z"
        fill="#FEF8F5"
      />
    </svg>
  );
};

export default CrossIcon;
