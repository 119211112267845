import * as React from 'react';

function CrownIcon({ size = 58 }) {
  return (
    <svg
      width={size}
      height={(size * 47) / 58}
      viewBox="0 0 58 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0004 15.97C17.2292 15.97 15.7998 17.3994 15.7998 19.1706C15.7998 20.3377 16.4206 21.3566 17.3509 21.9157C16.5058 25.2167 14.7727 28.4211 12.5161 28.7742H11.4065C9.72293 28.5661 7.704 27.4692 6.41999 25.6025C6.94465 25.0322 7.26542 24.2715 7.26542 23.4374C7.26542 21.6726 5.82957 20.2367 4.06476 20.2367C2.29995 20.2367 0.792969 21.6726 0.792969 23.4374C0.792969 25.0057 1.98309 26.3143 3.48666 26.5862L6.59855 37.1697C6.99484 38.5175 8.23176 39.4431 9.63661 39.4431L28.3689 39.4431C29.7738 39.4431 31.0107 38.5175 31.407 37.1697L34.519 26.5856C36.0211 26.3124 37.2095 25.0046 37.2095 23.4374C37.2095 21.6726 35.7026 20.2367 33.9377 20.2367C32.1729 20.2367 30.7371 21.6726 30.7371 23.4374C30.7371 24.2724 31.0586 25.0338 31.5842 25.6043C30.5362 27.1271 28.9991 28.1372 27.5543 28.5702C27.229 28.6677 26.9084 28.736 26.599 28.7742H25.4894C23.2324 28.421 21.4988 25.215 20.6542 21.9132C21.5822 21.3535 22.2011 20.3359 22.2011 19.1706C22.2011 17.3994 20.7716 15.97 19.0004 15.97Z"
        fill="#D42525"
      />
      <g opacity="0.5">
        <path
          d="M6.7742 2.8791C6.77117 2.8246 6.86681 2.79654 6.89371 2.84405C7.29201 3.5476 7.82706 3.83995 8.63429 3.79511C8.68879 3.79208 8.71685 3.88772 8.66934 3.91462C7.96579 4.31292 7.67344 4.84796 7.71828 5.6552C7.72131 5.7097 7.62567 5.73775 7.59877 5.69025C7.20047 4.9867 6.66542 4.69434 5.85819 4.73919C5.80369 4.74221 5.77563 4.64657 5.82314 4.61968C6.52669 4.22138 6.81905 3.68633 6.7742 2.8791Z"
          fill="#D42525"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M53.6473 25.1618C53.6236 25.1006 53.7226 25.0329 53.7711 25.0773C54.488 25.7344 55.2087 25.8702 56.1156 25.5191C56.1768 25.4954 56.2444 25.5945 56.2001 25.6429C55.543 26.3598 55.4072 27.0805 55.7582 27.9874C55.7819 28.0487 55.6829 28.1163 55.6345 28.0719C54.9176 27.4148 54.1969 27.279 53.29 27.6301C53.2287 27.6538 53.1611 27.5547 53.2055 27.5063C53.8626 26.7894 53.9984 26.0687 53.6473 25.1618Z"
          fill="#D42525"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M34.2593 4.20301C34.2633 4.12186 34.4092 4.09521 34.4416 4.16971C34.9218 5.2731 35.6683 5.78908 36.8702 5.84828C36.9513 5.85228 36.9779 5.99821 36.9034 6.03063C35.8001 6.51077 35.2841 7.25728 35.2249 8.45915C35.2209 8.5403 35.0749 8.56694 35.0425 8.49244C34.5624 7.38906 33.8159 6.87307 32.614 6.81388C32.5329 6.80988 32.5062 6.66394 32.5807 6.63153C33.6841 6.15139 34.2001 5.40488 34.2593 4.20301Z"
          fill="#D42525"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M44.6736 29.636C44.667 29.5169 44.876 29.4556 44.9348 29.5594C45.805 31.0966 46.9741 31.7354 48.7378 31.6374C48.8569 31.6308 48.9182 31.8398 48.8144 31.8985C47.2772 32.7688 46.6384 33.9379 46.7364 35.7016C46.743 35.8207 46.5341 35.882 46.4753 35.7782C45.605 34.241 44.436 33.6022 42.6722 33.7002C42.5531 33.7068 42.4918 33.4978 42.5956 33.4391C44.1329 32.5688 44.7716 31.3998 44.6736 29.636Z"
          fill="#D42525"
        />
      </g>
      <path
        d="M49.1963 10.2675C49.3032 10.0757 49.6888 10.1859 49.6781 10.4053C49.5201 13.6545 50.7113 15.7991 53.5531 17.3822C53.745 17.489 53.6348 17.8746 53.4154 17.864C50.1662 17.706 48.0216 18.8972 46.4385 21.739C46.3316 21.9309 45.946 21.8207 45.9567 21.6013C46.1147 18.3521 44.9235 16.2074 42.0817 14.6244C41.8898 14.5175 42 14.1319 42.2194 14.1426C45.4686 14.3006 47.6132 13.1094 49.1963 10.2675Z"
        fill="#D42525"
      />
      <path
        d="M17.9974 1.37346C18.0419 1.25538 18.2704 1.28551 18.2828 1.41111C18.467 3.27129 19.3249 4.38989 21.0737 5.05009C21.1917 5.09467 21.1616 5.32314 21.036 5.33557C19.1758 5.51971 18.0572 6.3776 17.397 8.1264C17.3524 8.24448 17.124 8.21434 17.1115 8.08874C16.9274 6.22857 16.0695 5.10996 14.3207 4.44976C14.2026 4.40518 14.2328 4.17672 14.3584 4.16429C16.2185 3.98014 17.3372 3.12225 17.9974 1.37346Z"
        fill="#D42525"
      />
    </svg>
  );
}

export default CrownIcon;
