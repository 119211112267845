import React from 'react';

const ChatBubbleSparkleIcon = () => {
  return (
    <svg
      width="87"
      height="47"
      viewBox="0 0 87 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0074 27.9858C32.0074 20.8095 37.8237 14.9932 45.0001 14.9932C52.1764 14.9932 57.9928 20.8095 57.9928 27.9858C57.9928 35.1622 52.1764 40.9785 45.0001 40.9785C42.6938 40.9785 40.533 40.3715 38.6554 39.3171C38.3755 39.16 38.0462 39.1158 37.7348 39.1936L32.4781 40.5078L33.7923 35.2511C33.8701 34.9398 33.8259 34.6104 33.6688 34.3306C32.6144 32.4529 32.0074 30.2921 32.0074 27.9858ZM45.0001 12.4785C36.4349 12.4785 29.4928 19.4207 29.4928 27.9858C29.4928 30.5583 30.1263 32.9814 31.2344 35.1144L29.5303 41.9309C29.4232 42.3594 29.5487 42.8126 29.861 43.1249C30.1733 43.4372 30.6266 43.5627 31.055 43.4556L37.8715 41.7515C40.0046 42.8596 42.4277 43.4932 45.0001 43.4932C53.5652 43.4932 60.5074 36.551 60.5074 27.9858C60.5074 19.4207 53.5652 12.4785 45.0001 12.4785ZM45.1979 26.4025C44.4312 26.4025 43.8125 27.0213 43.8125 27.7879C43.8125 28.5546 44.4312 29.1733 45.1979 29.1733C45.9646 29.1733 46.5833 28.5546 46.5833 27.7879C46.5833 27.0213 45.9646 26.4025 45.1979 26.4025ZM48.9583 27.7879C48.9583 27.0213 49.5771 26.4025 50.3437 26.4025C51.1104 26.4025 51.7292 27.0213 51.7292 27.7879C51.7292 28.5546 51.1104 29.1733 50.3437 29.1733C49.5771 29.1733 48.9583 28.5546 48.9583 27.7879ZM40.0521 26.4025C39.2854 26.4025 38.6667 27.0213 38.6667 27.7879C38.6667 28.5546 39.2854 29.1733 40.0521 29.1733C40.8188 29.1733 41.4375 28.5546 41.4375 27.7879C41.4375 27.0213 40.8188 26.4025 40.0521 26.4025Z"
        fill="#D42525"
      />
      <g opacity="0.5">
        <path
          d="M13.305 12.7939C13.2985 12.677 13.5035 12.6168 13.5612 12.7187C14.4153 14.2274 15.5626 14.8543 17.2936 14.7581C17.4105 14.7516 17.4706 14.9567 17.3688 15.0144C15.8601 15.8685 15.2332 17.0158 15.3294 18.7467C15.3359 18.8636 15.1308 18.9238 15.0731 18.8219C14.219 17.3133 13.0717 16.6864 11.3407 16.7825C11.2239 16.789 11.1637 16.5839 11.2656 16.5263C12.7742 15.6722 13.4011 14.5248 13.305 12.7939Z"
          fill="#D42525"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M2.78763 18.8034C2.78989 18.7378 2.90749 18.7145 2.93458 18.7743C3.3358 19.6602 3.94522 20.0682 4.91713 20.1016C4.98275 20.1038 5.00603 20.2215 4.94622 20.2485C4.06036 20.6498 3.65236 21.2592 3.61894 22.2311C3.61668 22.2967 3.49908 22.32 3.47199 22.2602C3.07077 21.3743 2.46135 20.9663 1.48944 20.9329C1.42382 20.9306 1.40054 20.813 1.46035 20.7859C2.34621 20.3847 2.75421 19.7753 2.78763 18.8034Z"
          fill="#D42525"
        />
      </g>
      <path
        d="M10.6462 22.6257C10.6574 22.5723 10.757 22.5699 10.7707 22.6228C10.9734 23.4054 11.4146 23.8263 12.206 23.9918C12.2594 24.0029 12.2618 24.1026 12.2089 24.1163C11.4263 24.319 11.0054 24.7602 10.8399 25.5516C10.8288 25.605 10.7291 25.6073 10.7154 25.5545C10.5127 24.7719 10.0715 24.351 9.28014 24.1855C9.22671 24.1743 9.22436 24.0747 9.2772 24.061C10.0598 23.8583 10.4807 23.4171 10.6462 22.6257Z"
        fill="#D42525"
      />
      <g opacity="0.5">
        <path
          d="M27.7211 1.68197C27.7543 1.60365 27.9073 1.63004 27.9123 1.71494C27.9867 2.97237 28.5345 3.74845 29.6944 4.23965C29.7727 4.27281 29.7463 4.42584 29.6614 4.43086C28.404 4.50523 27.6279 5.05303 27.1367 6.21294C27.1036 6.29125 26.9505 6.26487 26.9455 6.17996C26.8712 4.92253 26.3234 4.14645 25.1634 3.65526C25.0851 3.62209 25.1115 3.46906 25.1964 3.46404C26.4538 3.38968 27.2299 2.84188 27.7211 1.68197Z"
          fill="#D42525"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M82.1424 26.0925C82.1187 26.0313 82.2177 25.9637 82.2661 26.008C82.9831 26.6651 83.7038 26.8009 84.6107 26.4499C84.6719 26.4262 84.7395 26.5252 84.6951 26.5736C84.0381 27.2905 83.9023 28.0112 84.2533 28.9181C84.277 28.9794 84.178 29.047 84.1296 29.0026C83.4127 28.3456 82.692 28.2097 81.785 28.5608C81.7238 28.5845 81.6562 28.4855 81.7006 28.4371C82.3576 27.7201 82.4934 26.9994 82.1424 26.0925Z"
          fill="#D42525"
        />
      </g>
      <path
        d="M57.7995 3.26757C57.7904 3.19675 57.9125 3.15105 57.9521 3.21046C58.5386 4.09034 59.2645 4.42077 60.3133 4.2854C60.3841 4.27626 60.4298 4.39836 60.3704 4.43796C59.4905 5.02455 59.1601 5.75037 59.2954 6.79916C59.3046 6.86997 59.1825 6.91568 59.1429 6.85627C58.5563 5.97639 57.8304 5.64596 56.7817 5.78132C56.7108 5.79046 56.6651 5.66837 56.7246 5.62876C57.6044 5.04217 57.9349 4.31635 57.7995 3.26757Z"
        fill="#D42525"
      />
      <g opacity="0.6">
        <path
          d="M73.1739 30.5686C73.1673 30.4495 73.3763 30.3882 73.435 30.492C74.3053 32.0292 75.4743 32.668 77.2381 32.57C77.3572 32.5634 77.4185 32.7724 77.3147 32.8312C75.7775 33.7014 75.1387 34.8705 75.2367 36.6342C75.2433 36.7533 75.0343 36.8146 74.9756 36.7108C74.1053 35.1736 72.9362 34.5348 71.1725 34.6328C71.0534 34.6394 70.9921 34.4304 71.0959 34.3717C72.6331 33.5014 73.2719 32.3324 73.1739 30.5686Z"
          fill="#D42525"
        />
      </g>
      <path
        d="M75.4437 12.8483C75.4379 12.6283 75.8267 12.5266 75.9294 12.7212C77.4511 15.604 79.5727 16.8457 82.8312 16.7609C83.0512 16.7552 83.1529 17.1439 82.9583 17.2467C80.0756 18.7683 78.8338 20.8899 78.9187 24.1484C78.9244 24.3685 78.5356 24.4702 78.4329 24.2755C76.9112 21.3928 74.7897 20.151 71.5311 20.2359C71.3111 20.2416 71.2094 19.8528 71.404 19.7501C74.2867 18.2285 75.5285 16.1069 75.4437 12.8483Z"
        fill="#D42525"
      />
      <path
        d="M15.6494 27.5817C15.7325 27.3798 16.1261 27.4436 16.1411 27.6615C16.3644 30.8878 17.7908 32.8661 20.7813 34.0973C20.9832 34.1804 20.9194 34.574 20.7016 34.5891C17.4753 34.8124 15.4969 36.2387 14.2657 39.2292C14.1826 39.4312 13.789 39.3674 13.774 39.1495C13.5507 35.9232 12.1243 33.9449 9.1338 32.7137C8.93188 32.6305 8.99568 32.237 9.21352 32.2219C12.4398 31.9986 14.4182 30.5722 15.6494 27.5817Z"
        fill="#D42525"
      />
    </svg>
  );
};

export default ChatBubbleSparkleIcon;
