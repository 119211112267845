import React from 'react';

const CommentSparkleIcon = ({ size = 24, color = '#D42525' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6453 2.59273C18.6164 2.46909 18.3846 2.46909 18.3556 2.59273C17.9272 4.42389 16.9244 5.42674 15.0932 5.85515C14.9696 5.88408 14.9696 6.11592 15.0932 6.14485C16.9244 6.57326 17.9272 7.57611 18.3556 9.40727C18.3846 9.53091 18.6164 9.53091 18.6453 9.40727C19.0737 7.57611 20.0766 6.57326 21.9078 6.14485C22.0314 6.11592 22.0314 5.88408 21.9078 5.85515C20.0766 5.42674 19.0737 4.42389 18.6453 2.59273ZM10.604 7.06624C10.5833 6.97792 10.4177 6.97792 10.397 7.06624C10.091 8.37421 9.37469 9.09053 8.06672 9.39654C7.97841 9.4172 7.97841 9.5828 8.06672 9.60346C9.37469 9.90947 10.091 10.6258 10.397 11.9338C10.4177 12.0221 10.5833 12.0221 10.604 11.9338C10.91 10.6258 11.6263 9.90947 12.9343 9.60346C13.0226 9.5828 13.0226 9.4172 12.9343 9.39654C11.6263 9.09053 10.91 8.37421 10.604 7.06624ZM15.4384 11.0397C15.4508 10.9868 15.5502 10.9868 15.5626 11.0397C15.7462 11.8245 16.176 12.2543 16.9607 12.4379C17.0137 12.4503 17.0137 12.5497 16.9607 12.5621C16.176 12.7457 15.7462 13.1755 15.5626 13.9603C15.5502 14.0132 15.4508 14.0132 15.4384 13.9603C15.2548 13.1755 14.825 12.7457 14.0402 12.5621C13.9872 12.5497 13.9872 12.4503 14.0402 12.4379C14.825 12.2543 15.2548 11.8245 15.4384 11.0397ZM4.78906 7.01001C4.78906 5.76737 5.79642 4.76001 7.03906 4.76001H12.0005C12.4147 4.76001 12.7505 4.42422 12.7505 4.01001C12.7505 3.5958 12.4147 3.26001 12.0005 3.26001H7.03906C4.96799 3.26001 3.28906 4.93894 3.28906 7.01001V21.01C3.28906 21.3134 3.47179 21.5868 3.75205 21.7029C4.03231 21.819 4.35489 21.7548 4.56939 21.5403L7.84972 18.26H18.0391C20.1101 18.26 21.7891 16.5811 21.7891 14.51V11.5C21.7891 11.0858 21.4533 10.75 21.0391 10.75C20.6248 10.75 20.2891 11.0858 20.2891 11.5V14.51C20.2891 15.7527 19.2817 16.76 18.0391 16.76H7.53906C7.34015 16.76 7.14938 16.839 7.00873 16.9797L4.78906 19.1993V7.01001Z"
        fill={color}
      />
    </svg>
  );
};

export default CommentSparkleIcon;
