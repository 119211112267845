import React from 'react';

const BottomRightScribble = ({ size = 68, color = '#F7CC9C', ...rest }) => {
  return (
    <svg
      width={size}
      height={size * (75 / 68)}
      viewBox="0 0 68 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.66361 51.9906C11.0506 47.4647 8.5932 77.2243 17.9523 70.3343C24.3735 65.6071 9.16437 45.6277 18.2736 39.6471C27.3828 33.6665 18.1406 68.6575 28.7092 63.1673C36.464 59.1388 20.8381 35.2088 30.1704 30.5075C39.5026 25.8061 30.1242 58.1904 40.7728 52.8025C49.1377 48.5701 51.6443 17.8494 42.4645 21.8852C33.2847 25.9209 48.9373 47.0564 53.9461 42.1337C58.9549 37.211 59.1091 30.5784 58.5804 27.2125"
        stroke={color}
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.3413 8.69105C63.436 11.5061 59.7536 13.8892 56.7944 15.4761C55.0653 16.4033 53.2722 14.764 53.8906 12.9019C54.8617 9.97732 56.0492 6.44235 56.689 4.69053C57.5667 2.28751 60.4435 0.638895 63.5042 2.70979C66.5648 4.78067 66.3766 7.16122 65.3413 8.69105Z"
        fill={color}
      />
    </svg>
  );
};

export default BottomRightScribble;
